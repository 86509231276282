<template>
  <div>
    <prism-editor
      class="my-editor"
      v-model="item.Value"
      :highlight="highlighter"
      :readonly="
        $helpers.itemNeedsDisable(item.ReadOnly, needsDisable, editItemAdmin)
      "
      line-numbers
    ></prism-editor>
    <v-row no-gutters v-if="$store.getters.editMode">
      <v-col cols="12">
        <hr />
      </v-col>
      <v-col
        cols="12"
        :lg="
          item.colWidth < 7
            ? item.colWidth < 5
              ? 12
              : 4
            : item.colWidth < 10
            ? 4
            : 2
        "
      >
        <v-layout column>
          <v-switch
            flat
            :ripple="false"
            v-model="item.ReadOnly"
            :label="$t('Read-only')"
            hide-details="auto"
          ></v-switch>
          <v-switch
            flat
            :ripple="false"
            v-model="item.RemoveCard"
            hide-details="auto"
            class="float-left mr-3"
            :label="$t('Remove frame')"
          ></v-switch>
          <v-text-field
            v-model.number="item.colWidth"
            hide-details="auto"
            type="number"
            max="12"
            min="1"
            :label="$t('Column width')"
            class="mt-4"
            outlined
            dense
          ></v-text-field>
        </v-layout>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";
export default {
  components: { PrismEditor },

  props: {
    item: {
      type: [Array, Object],
    },
    needsDisable: {
      type: Boolean,
    },
    editItemAdmin: {
      type: Boolean,
    },
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.javascript); //returns html
    },
  },
};
</script>

<style  scoped>
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}
.mw-w {
  min-width: 2rem;
  max-width: 8rem;
}
</style>