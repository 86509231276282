var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('prism-editor',{staticClass:"my-editor",attrs:{"highlight":_vm.highlighter,"readonly":_vm.$helpers.itemNeedsDisable(_vm.item.ReadOnly, _vm.needsDisable, _vm.editItemAdmin),"line-numbers":""},model:{value:(_vm.item.Value),callback:function ($$v) {_vm.$set(_vm.item, "Value", $$v)},expression:"item.Value"}}),(_vm.$store.getters.editMode)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('hr')]),_c('v-col',{attrs:{"cols":"12","lg":_vm.item.colWidth < 7
          ? _vm.item.colWidth < 5
            ? 12
            : 4
          : _vm.item.colWidth < 10
          ? 4
          : 2}},[_c('v-layout',{attrs:{"column":""}},[_c('v-switch',{attrs:{"flat":"","ripple":false,"label":_vm.$t('Read-only'),"hide-details":"auto"},model:{value:(_vm.item.ReadOnly),callback:function ($$v) {_vm.$set(_vm.item, "ReadOnly", $$v)},expression:"item.ReadOnly"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"flat":"","ripple":false,"hide-details":"auto","label":_vm.$t('Remove frame')},model:{value:(_vm.item.RemoveCard),callback:function ($$v) {_vm.$set(_vm.item, "RemoveCard", $$v)},expression:"item.RemoveCard"}}),_c('v-text-field',{staticClass:"mt-4",attrs:{"hide-details":"auto","type":"number","max":"12","min":"1","label":_vm.$t('Column width'),"outlined":"","dense":""},model:{value:(_vm.item.colWidth),callback:function ($$v) {_vm.$set(_vm.item, "colWidth", _vm._n($$v))},expression:"item.colWidth"}})],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }